import React, {Component}
from 'react';
        import 'bootstrap/dist/css/bootstrap.css';
        import './kanban.css';
        import axios from 'axios';
        import Table from 'react-bootstrap/Table'
        import Button from 'react-bootstrap/Button';

import { Router, Route, Link , useHistory } from 'react-router-dom';
        class KanbanComponent extends React.Component {

        constructor(props) {
        super(props);
                this.getHeader = this.getHeader.bind(this);
                this.getRowsData = this.getRowsData.bind(this);
                this.getKeys = this.getKeys.bind(this);
                this.displayCard = this.displayCard.bind(this);
                this.getCard = this.getCard.bind(this);
                this.request = "http://160.98.46.178:8080/server/resources/";
                this.state = {
                kanban: "",
                        columns:[]
                }
        }

        componentDidMount() {
        axios.get(this.request + "employe/getKanbanByID?id=1")
                .then(res => {
                const kanban = res.data;
                        this.state.columns = res.data.columns;
                        this.setState({kanban});
                })
        }

        getKeys = function () {

        }

        getHeader = function () {
        console.log(this.state.columns);
                return this.state.columns.map((column) => <th>{column.name}</th>);
        }

        getRowsData = function () {
        console.log(this.state.columns);
                return this.state.columns.map((column) => <td>{  this.displayCard(column)   }</td>);
        }

        displayCard = function(column) {
        console.log(column.card);
        return column.card.map((card) => <div><a href={'/card/' + card.id}>{this.getCard(card)}</a> <br/></div>); // style={{backgroundColor: 'red'}}>
        }
        

        getCard = function(card){
        console.log(card.color)
                if (card.color === "blue"){
        return <div class="cardBlue">{card.confirmationNumber}<br/>{card.specification} </div>;
        } else if (card.color === "orange"){
        return <div class="cardOrange">{card.confirmationNumber}<br/>{card.specification}</div>;
        } else if (card.color === "yellow"){
        return <div class="cardYellow">{card.confirmationNumber}<br/>{card.specification}</div>;
        }else if (card.color === "purple"){
        return <div class="cardPurple">{card.confirmationNumber}<br/>{card.specification}</div>;
        }else if (card.color === "white"){
        return <div class="cardWhite">{card.confirmationNumber}<br/>{card.specification}</div>;
        }    }

        render() {
        return (
                <div>
                    <br/>
                    <h2>{this.state.kanban.name}</h2>
                    <p>{this.state.kanban.desription}</p>
                    <br/>

                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>{this.getHeader()}</tr>
                        </thead>
                        <tbody>
                            {this.getRowsData()}
                        </tbody>
                    </Table>
                </div>

                );
        }
        }
        export default KanbanComponent;
