import React, {Component}
from 'react';
        import 'bootstrap/dist/css/bootstrap.css';
        import './card.css';
        import axios from 'axios';
        import Table from 'react-bootstrap/Table'
        import { Row, Col, Button, Form }
from 'react-bootstrap'

        import {  useParams }
from "react-router-dom";
        import { Redirect } from 'react-router-dom'
        class ManageCards extends React.Component {

        constructor(props) {

        super(props);
                this.displayTR = this.displayTR.bind(this);
                this.displayTH = this.displayTH.bind(this);
                this.handleEdit = this.handleEdit.bind(this);
                this.handleRemove = this.handleRemove.bind(this);
                this.request = "http://160.98.46.178:8080/server/resources/";
                this.state = {
                cards : [],
                        redirect: false
                }

        }

        componentDidMount() {
        axios.get(this.request + "employe/getCards")
                .then(res => {
                const cards = res.data;
                        this.state.cards = res.data;
                        this.setState({cards});
                })
        }

        setRedirect = () => {
        this.setState({
        redirect: true
        })
        }

        renderRedirect = () => {

        if (this.state.redirect) {
        return <Redirect to='/NewCard' />
        }
        }

        displayTR = function () {
        return this.state.cards.map((card) => <>{this.displayTH(card)}</>);
        }

        displayTH = function (card) {
        return <tr><td>{card.confirmationNumber}</td><td>{card.clientName}</td>
    <td>{card.produktGroup}</td><td>{card.numberPiece}</td>
    <td> <Button onClick={() => this.handleEdit(card.id)}  style={{marginLeft: '10px'}} variant="warning">Edit</Button>
        <Button onClick={() => this.handleRemove(card.id)} style={{marginLeft: '10px'}}  variant="danger">Remove</Button>
    </td></tr>;
        }

        handleEdit = id => {


        }

        handleRemove = id => {
                axios.delete(this.request + "administrator/deleteCard?id=" + id)
                .then(res => {

                window.location.reload();
                })


        }
        render() {
        return (
<Row>
    <Col>
    <h4>Manage cards</h4>
    {this.renderRedirect()}
    <input class="btn btn-success" style={{marginBottom: '10px'}} onClick={this.setRedirect} type="submit" value="New" />
    <Table bordered size="sm">
        <thead>
            <tr>
                <th>Confirmation Number</th>
                <th>Client Name</th>
                <th>Product Group</th>
                <th>Number of Piece</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {this.displayTR()}
        </tbody>
    </Table>
    </Col>
</Row>
                );
        }
        }
export default ManageCards;
