import React, {Component}
from 'react';
        import 'bootstrap/dist/css/bootstrap.css';
        import './card.css';
        import axios from 'axios';
        import Table from 'react-bootstrap/Table'
        import { LinkContainer } from 'react-router-bootstrap'
        import { Row, Col, Button, Form } from 'react-bootstrap'
        import {Link} from 'react-router-dom'
        import { Redirect } from 'react-router-dom'
        import {  useParams } from "react-router-dom";
        class NewCardComponent extends React.Component {

        constructor(props) {
        super(props);
                this.handleSubmit = this.handleSubmit.bind(this);
                this.request = "http://160.98.46.178:8080/server/resources/";
                this.state = {
                id: "",
                        confirmationNumber: "",
                        clientName: "",
                        technicalOfficialDeadlines: "",
                        tli: "",
                        ft: "",
                        produktGroup: "",
                        flag: "",
                        numberPiece: "",
                        mainMasse: "",
                        importantVerification: "",
                        specification: "",
                        numberPosVerification: "",
                        point9: "",
                        point8: "",
                        abbrVendor: "",
                        color: "blue",
                        fkEmp: "",
                        Produkttyp:"",
                        columns:[],
                        redirect: false
                }

        }




        componentDidMount() {
        axios.get(this.request + "employe/getColumnsByKanbanId?id=1")
                .then(res => {
                const columns = res.data;
                        this.state.columns = columns;
                        this.setState({columns});
                })
        }


        handleSubmit = (event) => {
 event.preventDefault();
  var request = this.request + "administrator/createCard?confirmationNumber=" + event.target.elements.confirmationNumber.value
          + "&clientName=" + event.target.elements.clientName.value
          + "&technicalOfficialDeadlines=" + event.target.elements.technicalOfficialDeadlines.value
          + "&tli=" + event.target.elements.tli.value
          + "&ft=" + event.target.elements.ft.value
          + "&produktGroup=" + event.target.elements.produktGroup.value
          + "&flag=" + event.target.elements.flag.value
          + "&numberPiece=" + event.target.elements.numberPiece.value
          + "&mainMasse=" + event.target.elements.mainMasse.value 
          + "&importantVerification=" + event.target.elements.importantVerification.value
          + "&specification=" + event.target.elements.specification.value
          + "&numberPosVerification=" + event.target.elements.numberPosVerification.value 
          + "&point9=" + event.target.elements.point9.value
          + "&point8=" +  event.target.elements.point8.value  
          + "&abbrVendor=" +  event.target.elements.abbrVendor.value 
          + "&color=" + event.target.elements.ColorSelect.value  
          + "&fkEmp=1";
  console.log(request)
   axios.post(request)
                .then(res => {
                const columns = res.data;
                        this.state.columns = columns;
                        this.setState({columns});
                })
  
  

  
                };
                
                
                
                
                
                render() {
        return (
<div className="whiteCard">       
    <Form onSubmit={this.handleSubmit}>
        <Form.Row>
            <Col md="5">
            <Form.Label>AB nummer</Form.Label>
            <Form.Control name="confirmationNumber" type="text"  required />
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="7">
            <Form.Label>Kundename</Form.Label>
            <Form.Control name="clientName" type="text" required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
        </Form.Row>

        <Form.Row>
            <Col md="3">
            <Form.Label>Termin TB KW</Form.Label>
            <Form.Control name="technicalOfficialDeadlines" type="date"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="2">
            <Form.Label>Kurzzeichen TB-Mitarbeiter</Form.Label>
            <Form.Control name="abbrVendo" type="text"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="4">
            <Form.Label>Anlieferungstermin Fremdfertigung oder Edelparts KW</Form.Label>
            <Form.Control name="tli" type="text"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="3">
            <Form.Label>Fertigungstermin</Form.Label>
            <Form.Control name="ft" type="text"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
        </Form.Row>

        <Form.Row>
            <Col md="9">
            <Form.Label>Produktbezeichnung</Form.Label>
            <Form.Control name="produktGroup" type="text"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="2">
            <Form.Label>Produkttyp</Form.Label>
            <Form.Control name="Produkttyp" type="text"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="1">
            <Form.Label>!!</Form.Label>
            <Form.Control name="flag" type="text"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
        </Form.Row>

        <Form.Row>
            <Col md="2">
            <Form.Label>Stk.Zahl</Form.Label>
            <Form.Control name="numberPiece" type="text"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="5">
            <Form.Label>Haupt-Masse</Form.Label>
            <Form.Control name="mainMasse" type="text"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="4">
            <Form.Label>V2A / V4A / wichtige Spez.</Form.Label>
            <Form.Control name="importantVerification" type="text"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="1">
            <Form.Label>9</Form.Label>
            <Form.Control name="point9" type="text" required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
        </Form.Row>



        <Form.Row>
            <Col md="8">
            <Form.Label>Spezifikiation</Form.Label>
            <Form.Control name="specification" type="text" required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="2">
            <Form.Label>Pos. No</Form.Label>
            <Form.Control name="numberPosVerification" type="text"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="1">
            <Form.Label>8</Form.Label>
            <Form.Control name="point8" type="text" required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
            <Col md="1">
            <Form.Label>Verkäufer</Form.Label>
            <Form.Control name="abbrVendor" type="text"  required/>
            <Form.Control.Feedback type="invalid">  Required ! </Form.Control.Feedback>
            </Col>
        </Form.Row>
        <Col>
         <label style={{marginTop: '10px'}}>
          Color :
          <select value={this.state.value} name="ColorSelect" style={{marginLeft: '10px'}} onChange={this.handleChange}>
            <option value="blue">blue</option>
            <option value="yellow">yellow</option>
            <option value="orange">orange</option>
            <option value="purple">purple</option>
            <option value="white">white</option>
          </select>
        </label>
        </Col>
        <Col>
        <input class="btn btn-primary"  style={{marginTop: '10px'}} type="submit" value="Create card" required/>
            </Col>           
    </Form>         
</div>



                );
        }
        }
export default NewCardComponent;
