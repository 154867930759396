import React from 'react'
        import { Row, Col, Button, Form } from 'react-bootstrap'
import axios from 'axios';
        class Login extends React.Component {
    constructor(props) {
        super(props)
        this.request = "http://160.98.46.178:8080/server/resources/";
        this.state = {inputEmail: 'remi@gmail.com', inputPassword: 'remi'}
    }

    handleSubmitAdmin = () => {
        console.log(this.state.inputEmail + " " + this.state.inputPassword)
         axios.get(this.request + "employe/loginAdmin?username="+this.state.inputEmail + "&password=" + this.state.inputPassword)
                .then(res => {
                    this.props.refreshRole()
                })
        
    }

    render() {

        return (
                <div>
                    <Row>
                        <Col md="6">                
                        <Form onSubmit={this.handleSubmitAdmin}>
                            <Form.Group controlId="login-admin-email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={this.state.inputEmail}
                                    onChange={e => this.setState({inputEmail: e.target.value })}
                                    />
                            </Form.Group>
                            <Form.Group controlId="login-admin-password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={this.state.inputPassword}
                                    onChange={e => this.setState({inputPassword: e.target.value })}
                                    />
                            </Form.Group>
                
                            <Button onClick={this.handleSubmitAdmin}>Login</Button>
                        </Form>
                        </Col>
                    </Row>
                </div>

                                )
                    }
                }

                export default Login
