import React from 'react'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  Link
} from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap'
import axios from 'axios';
import NewCard from './Components/NewCardComponent.js'
import Kanban from './Components/KanbanComponent.js'
import Card from './Components/CardComponent.js'

import Login from './Components/Login.js'
import ManageCards from './Components/ManageCards.js'
import './App.css';


class App extends React.Component {
    
    

  constructor(props) {
    super(props)
    this.request = "http://160.98.46.178:8080/server/resources/";
    
    this.state = {
      role: null
    }
  }

  componentDidMount() {
    this.mounted = true
    this.refreshRole()
  }

  componentWillUnmount() {
    this.mounted = false
  }
  

  refreshRole = () => {
     axios.get(this.request + "employe/getSession")
                .then(res => {
                   if (this.mounted) this.setState({ role: res.data.role })
                })
  }

  render() {
    if (!this.state.role) return (
      <h1 className="text-center">Loading...</h1>
    )

    return (
      <BrowserRouter basename="/eKanban">
      <Container fluid="md">
        <Row>
          <Col>
            <Navbar bg="light" expand="sm">
            <Navbar.Brand>eKanban</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <LinkContainer exact to="/kanbans"><Nav.Link>Show Kanbans</Nav.Link></LinkContainer>
                  {this.state.role !== 'admin'  ? null : (
                    <LinkContainer exact to="/ManageCards"><Nav.Link>Manage Cards</Nav.Link></LinkContainer>
                  )}
                  {this.state.role !== 'admin' ? null : (
                    <LinkContainer exact to="/ManageKanbans"><Nav.Link>Manage Kanbans</Nav.Link></LinkContainer>
                  )}
                  {this.state.role !== 'admin' ? null : (
                    <LinkContainer exact to="/ManageEmployes"><Nav.Link>Manage Employes</Nav.Link></LinkContainer>
                  )}
                 {this.state.role !== 'employe' ? null : (
                    <LinkContainer exact to="/login"><Nav.Link>Login</Nav.Link></LinkContainer>
                  )}
                  {this.state.role !== 'admin' ? null : (
                    <LinkContainer exact to="/login" onClick={e => {
                        e.preventDefault()

                   axios.get("http://160.98.46.178:8080/server/resources/employe/logoutAdmin")
                        .then(res => {
                         this.refreshRole()
                        })
                      }}>
                        <Nav.Link>Logout</Nav.Link>
                      </LinkContainer>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            </Col>
          </Row>
          <Row>
            <Col>

              <Switch>
                {this.state.role !== 'employe' ? null : (
                  <Route path="/login">
                    < Login refreshRole={this.refreshRole} />
                  </Route>
                )}
                {this.state.role !== 'admin' ? null : (
                  <Route path="/ManageCards">
                  < ManageCards />
                  </Route>
                )}
                {this.state.role !== 'admin' ? null : (
                  <Route path="/NewCard">
                  < NewCard />
                  </Route>
                )}
                {this.state.role !== 'admin' ? null : (
                  <Route path="/ManageKanbans">
                  </Route>
                )}
                {this.state.role !== 'admin'  ? null : (
                  <Route path="/ManageEmployes">
                  </Route>
                )}
                {this.state.role !== 'admin' ? null : (
                  <Route path="/accounts">
                  </Route>
                )}
                <Route exact path="/card/:id" children={ props => <Card {...props}/>} />
                <Route exact path="/kanbans">
                    < Kanban/>
                </Route>
                <Redirect path="/" to="/kanbans" />
              </Switch>

            </Col>
          </Row>
        </Container>
      </BrowserRouter>
    )
  }
}



export default App
