import React, {Component}
from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './card.css';
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import { LinkContainer } from 'react-router-bootstrap'
        import { Row, Col, Button, Form } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import {  useParams } from "react-router-dom";

class CardComponent extends React.Component {

    constructor(props) {
        super(props);
        this.request = "http://160.98.46.178:8080/server/resources/";
        this.onChangeFunc = this.onChangeFunc.bind(this);
        this.displayColumnList = this.displayColumnList.bind(this);
        this.addColumn = this.addColumn.bind(this);
        this.state = {
            card : "",
            columns:[],
            old_column:"",
            new_column:"",
            redirect: false
        }

    }
    
    setRedirect = () => {
    this.setState({
      redirect: true
    })
  }
  renderRedirect = () => {
       var requestStr = this.request + "employe/moveCard?idCard=" +  this.state.card.id + "&id_new_Column=" + this.state.new_column+ "&id_old_Column=" + this.state.old_column.id;
      
        axios.put(requestStr)
                .then(res => {
                   console.log("envoyer !!!")
                 //this.window.location.href="/kanbans";
                })
      
      
    if (this.state.redirect) {
      return <Redirect to='/kanbans' />
    }
  }
    
    

    componentDidMount() {
        let { id } = this.props.match.params;
        axios.get(this.request + "employe/getCardByID?id=" +  id)
                .then(res => {
                    const card = res.data;
                    this.state.card = res.data.card;
                    this.setState({card});
                })
                
         axios.get(this.request +"employe/getColumnByCardId?id=" +  id)
                .then(res => {
                    const column = res.data;
                    this.state.old_column = column;
                    this.setState({column});
                })
          axios.get(this.request +"employe/getColumnsByKanbanId?id=1")
                .then(res => {
                    const columns = res.data;
                    this.state.columns = columns;
                    if(columns[0].id === this.state.old_column.id){
                        this.state.new_column  = columns[1].id;
                    }else{
                       this.state.new_column  = columns[0].id; 
                    }
                    
                    this.setState({columns});
                })
    }
    
    
    displayColumnList = function () {
        return this.state.columns.map((column) =>{return (column.id != this.state.old_column.id) ? this.addColumn(column) : null});
        }
        
     addColumn = function (column) {
               
         return <option value={column.id}>{column.name}</option> ;
     }
        
    
  onChangeFunc(e) {
  this.state.new_column = e.target.value;
  console.log( this.state.new_column);
  }
  


    render() {
        return (
               <div> <div className={this.state.card.color + 'Card'}>       
                    <Form>
                        <Form.Row>
                            <Col md="5">
                            <Form.Label>AB nummer</Form.Label>
                            <Form.Control type="text" value={this.state.card.confirmationNumber} readOnly/>
                            </Col>
                            <Col md="7">
                            <Form.Label>Kundename</Form.Label>
                            <Form.Control type="text" value={this.state.card.clientName} readOnly/>
                            </Col>
                        </Form.Row>
                
                        <Form.Row>
                            <Col md="3">
                            <Form.Label>Termin TB KW</Form.Label>
                            <Form.Control type="text" value={this.state.card.technicalOfficialDeadlines} readOnly/>
                            </Col>
                            <Col md="2">
                            <Form.Label>Kurzzeichen TB-Mitarbeiter</Form.Label>
                            <Form.Control type="text" value="" readOnly/>
                            </Col>
                            <Col md="4">
                            <Form.Label>Anlieferungstermin Fremdfertigung oder Edelparts KW</Form.Label>
                            <Form.Control type="text" value={this.state.card.tli} readOnly/>
                            </Col>
                            <Col md="3">
                            <Form.Label>Fertigungstermin</Form.Label>
                           <Form.Control type="text" value={this.state.card.ft} readOnly/>
                            </Col>
                        </Form.Row>
                
                        <Form.Row>
                            <Col md="9">
                            <Form.Label>Produktbezeichnung</Form.Label>
                            <Form.Control type="text" value={this.state.card.produktGroup} readOnly/>
                            </Col>
                            <Col md="2">
                            <Form.Label>Produkttyp</Form.Label>
                            <Form.Control type="text" value="" readOnly/>
                            </Col>
                            <Col md="1">
                            <Form.Label>!!</Form.Label>
                            <Form.Control type="text" value={this.state.card.flag} readOnly/>
                            </Col>
                        </Form.Row>
                
                        <Form.Row>
                            <Col md="2">
                            <Form.Label>Stk.Zahl</Form.Label>
                            <Form.Control type="text" value={this.state.card.numberPiece} readOnly/>
                            </Col>
                            <Col md="5">
                            <Form.Label>Haupt-Masse</Form.Label>
                            <Form.Control type="text" value={this.state.card.mainMasse} readOnly/>
                            </Col>
                            <Col md="4">
                            <Form.Label>V2A / V4A / wichtige Spez.</Form.Label>
                            <Form.Control type="text" value={this.state.card.importantVerification} readOnly/>
                            </Col>
                            <Col md="1">
                            <Form.Label>9</Form.Label>
                            <Form.Control type="text" value={this.state.card.point9} readOnly/>
                            </Col>
                        </Form.Row>
                
                       
                
                        <Form.Row>
                            <Col md="8">
                            <Form.Label>Spezifikiation</Form.Label>
                            <Form.Control type="text" value={this.state.card.specification} readOnly/>
                            </Col>
                            <Col md="2">
                            <Form.Label>Pos. No</Form.Label>
                            <Form.Control type="text" value={this.state.card.numberPosVerification} readOnly/>
                            </Col>
                            <Col md="1">
                            <Form.Label>8</Form.Label>
                            <Form.Control type="text" value={this.state.card.point8} readOnly/>
                            </Col>
                            <Col md="1">
                            <Form.Label>Verkäufer</Form.Label>
                            <Form.Control type="text" value={this.state.card.abbrVendor} readOnly/>
                            </Col>
                        </Form.Row>
                    </Form>         
                </div>
                <div>
                <h4>Move this card</h4>
                </div>
                 <Form >
                        <Form.Row>
                            <Col md="5">
                            <Form.Label>Old column</Form.Label>
                            <Form.Control type="text" value={this.state.old_column.name} readOnly/>
                            </Col>
                            <Col md="5">
                            <Form.Label>New column</Form.Label>
                            <Form.Control as="select" size="md"  onChange={this.onChangeFunc} custom>
                               {this.displayColumnList()}
                            </Form.Control>
                            </Col>
                            
                            <Col md="2">
                            {this.renderRedirect()}
                            <input class="btn btn-primary inputApply" onClick={this.setRedirect} type="submit" value="Apply" />
                            </Col>
                            </Form.Row>
                       </Form> 
                </div>
                
                );
    }
}
export default CardComponent;
